var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inline action-button" },
    [
      this.type === "view"
        ? _c(
            "vs-button",
            {
              staticClass: "float-right mr-2 px-3 rounded-lg",
              attrs: { type: "border" },
              on: { click: _vm.handleAction }
            },
            [
              _c("feather-icon", {
                staticClass: "align-middle",
                attrs: {
                  icon: "EyeIcon",
                  svgClasses: "text-primary align-middle w-6"
                }
              })
            ],
            1
          )
        : this.type === "clone"
        ? _c(
            "vx-tooltip",
            {
              staticClass: "m-0",
              attrs: {
                text: _vm.$t("copy-button-tooltip"),
                position: "top",
                delay: ".3s"
              }
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mr-2 px-5 rounded-lg",
                  attrs: { type: "border" },
                  on: { click: _vm.handleAction }
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-nowrap" },
                    [
                      _c("feather-icon", {
                        staticClass: "align-middle",
                        attrs: {
                          icon: "CopyIcon",
                          svgClasses: "text-primary align-middle w-6"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : this.type === "edit"
        ? _c(
            "vs-button",
            {
              staticClass: "mr-2 px-5 rounded-lg",
              attrs: { type: "border" },
              on: { click: _vm.handleAction }
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-nowrap" },
                [
                  _c("feather-icon", {
                    staticClass: "align-middle",
                    attrs: {
                      icon: "Edit2Icon",
                      svgClasses: "text-primary align-middle w-6"
                    }
                  }),
                  _c("span", { staticClass: "hidden 2xl:inline-block ml-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("datatable.button.edit")) + " ")
                  ])
                ],
                1
              )
            ]
          )
        : this.type === "download"
        ? _c(
            "vs-button",
            {
              staticClass: "mr-2 rounded-lg",
              attrs: { type: "border" },
              on: { click: _vm.handleAction }
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-nowrap" },
                [
                  _c("feather-icon", {
                    staticClass: "align-middle",
                    attrs: {
                      icon: "DownloadIcon",
                      svgClasses: "text-primary align-middle w-6"
                    }
                  }),
                  _c("span", { staticClass: "hidden 2xl:inline-block ml-2" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("datatable.button.download")) + " "
                    )
                  ])
                ],
                1
              )
            ]
          )
        : this.type === "delete"
        ? _c(
            "vs-button",
            {
              staticClass: "delete-row rounded-lg px-3 py-2 mr-2 text-danger",
              attrs: { type: "border", color: "danger" },
              on: { click: _vm.handleAction }
            },
            [
              _c("feather-icon", {
                attrs: { icon: "TrashIcon", svgClasses: "align-middle w-6" }
              })
            ],
            1
          )
        : this.type === "editRow" && _vm.isEdited
        ? _c(
            "vs-button",
            {
              staticClass: "editRow rounded-lg px-3 py-2 mr-2",
              attrs: { type: "border" },
              on: { click: _vm.handleAction }
            },
            [
              _c("feather-icon", {
                staticClass: "align-middle",
                attrs: { icon: "SaveIcon", svgClasses: "align-middle w-6" }
              })
            ],
            1
          )
        : !_vm.isInArray
        ? _c(
            "div",
            [
              _c(
                "SKDataTableCustomAction",
                [
                  _c(_vm.customSlot, {
                    tag: "component",
                    attrs: { properties: _vm.customSlotProperties }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }