var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "overflow-ellipsis",
      attrs: { href: _vm.element[_vm.field.value] }
    },
    [
      _vm._v(
        " " + _vm._s(_vm._f("ellipsis")(_vm.element[_vm.field.value], 70)) + " "
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }