var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    { staticClass: "tr-values vs-table--tr tr-table-state-null" },
    [
      _vm.draggable
        ? _c(
            "td",
            [
              _c("feather-icon", {
                attrs: {
                  icon: "MenuIcon",
                  svgClasses: "cursor-pointer text-gray align-middle w-6 ml-3 "
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.fieldList, function(field, indextd) {
        return _c("td", { key: indextd, staticClass: "cell" }, [
          _c(
            "div",
            { staticClass: "flex items-center min-h-16" },
            [
              field.type == "actions"
                ? _c(
                    "div",
                    { staticClass: "flex w-full" },
                    _vm._l(_vm.element[field.value], function(action, index) {
                      return _c("DataTableAction", {
                        key: index,
                        staticClass: "flex",
                        attrs: {
                          type: action,
                          uuid: _vm.element.uuid,
                          element: _vm.element,
                          isEdited: _vm.edited,
                          customActions: _vm.customActions
                        },
                        on: {
                          action: function($event) {
                            return _vm.$emit(
                              "action",
                              action,
                              _vm.element.uuid,
                              _vm.element
                            )
                          }
                        }
                      })
                    }),
                    1
                  )
                : field.editable
                ? _c("DataTableEditable", {
                    ref: field.value + "-" + _vm.element.name,
                    refInFor: true,
                    staticClass: "w-full",
                    attrs: { field: field, element: _vm.element },
                    on: { edit: _vm.handleEdit },
                    model: {
                      value: _vm.element[field.value],
                      callback: function($$v) {
                        _vm.$set(_vm.element, field.value, $$v)
                      },
                      expression: "element[field.value]"
                    }
                  })
                : field.type == "image" ||
                  field.type == "bigimage" ||
                  field.type == "smallimage"
                ? _c("DataTableImage", {
                    attrs: { field: field, element: _vm.element }
                  })
                : field.type == "link"
                ? _c("DataTableLink", {
                    attrs: { field: field, element: _vm.element }
                  })
                : field.type == "check"
                ? _c("DataTableCheckbox", {
                    attrs: { field: field, element: _vm.element },
                    on: { check: _vm.handleCheck }
                  })
                : field.type === "multiselect"
                ? _c("DataTableMultiselect", {
                    attrs: { field: field, element: _vm.element },
                    on: { edit: _vm.handleEdit },
                    model: {
                      value: _vm.element[field.value],
                      callback: function($$v) {
                        _vm.$set(_vm.element, field.value, $$v)
                      },
                      expression: "element[field.value]"
                    }
                  })
                : field.type == "html"
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.element[field.value]) }
                  })
                : _c("div", [
                    _vm._v(" " + _vm._s(_vm.element[field.value]) + " ")
                  ])
            ],
            1
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }