<template>
  <div class="vs-component vs-con-table stripe vs-table-primary sk-datatable" :class="{stripe}">
    <header v-if="title" class="header-table vs-table--header">
      <h4>{{ title }}</h4>
    </header>
    <div class="flex flex-row items-center gap-2 align-middle mb-2 justify-end"  v-if="(paginationPosition === 'top' && hasPagination && totalPages > 1) || showRefreshButton">
      <vs-pagination  v-if="paginationPosition === 'top' && hasPagination && totalPages > 1"
                      class="pagination"
                      :total="totalPages"
                      v-model="currentPage" />
      <vs-icon v-if="showRefreshButton" class="text-3xl mr-2 cursor-pointer" icon="refresh" round color="primary" @click="handleRefresh"/>
    </div>

    <div class="con-tablex vs-table--content">
      <div class="vs-con-tbody vs-table--tbody">
        <table class="vs-table vs-table--tbody-table" >
          <DataTableHeader
            :draggable="draggable"
            :draggableHeadTitle="draggableHeadTitle"
            :headList="headList"            
            @sort="handleSort"
            ref="datatableSort"
          />
          

          <DataTableSearch
            ref="datatableSearch"
            :draggable="draggable"
            :searchList="searchList"
            @search="handleSelectFilter"
            @filter="handleFilter"
          />

          <draggable v-model="elementsInCurrentPage" tag="tbody" :disabled="!draggable">
            <DataTableRow :key="element.uuid || element.id || index" v-for="(element, index) in elementsInCurrentPage"
              :draggable="draggable"
              :edited="editedRows.includes(element)"
              :fieldList="fieldList"
              :element="element"
              :customActions = 'customActions'
              @edit="handleEditRow"
              @check="handleCheckbox"
              @action="handleAction"
          />
          </draggable>
        </table>
      </div>

      <vs-pagination  v-if="paginationPosition === 'bottom' && hasPagination && totalPages > 1"
                      class="pagination"
                      :total="totalPages"
                      v-model="currentPage" />
    </div>
    <div v-if="hasEditable" class="float-right mt-10 disabled">
          <vs-button class="button-save-all" :disabled="editedRows.length === 0" color="primary" type="filled rounded-lg w-48 mr-5" @click="saveAllRows()">{{ $t("Save") }}</vs-button>
          <vs-button color="primary" type="filled rounded-lg w-48" @click="addRow()">{{ $t("Add") }}</vs-button>
    </div>
  </div>
</template>

<script>

import DataTableHeader from '@/modules/Shared/Components/table/SKDataTableHeader.vue'
import DataTableSearch from '@/modules/Shared/Components/table/SKDataTableSearch.vue'
import DataTableRow from '@/modules/Shared/Components/table/SKDataTableRow.vue'
import draggable from 'vuedraggable'

export default {
  name: 'data-table',
  components: {
    DataTableHeader,
    DataTableSearch,
    DataTableRow,
    draggable
  },
  data(){
    return {
      currentPage: 1,
      perPage: 1,

      elementList: [],
      elementsFiltered: [],

      sort: {},
      filters: [],
      selectedFilters: {},
      editedRows: [],
    }
  },
  props: {
    title: { type: String, default: "" },
    stripe: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    sst: { type: Boolean, default: false },
    pagination: { type: Object, default: null },
    paginationPosition: { type: String, default: 'bottom' },
    config: { type: Array, default() {return []} },
    elements: { type: Array, default() {return []} },
    addRow: { type: Function, default: null },
    globalFilters: { type: Array, default() {return []} },
    deleteDialog: { type: Object, default: null },
    showRefreshButton: { type: Boolean, default: false },
    customActions:{type: Array,required:false},
    draggableHeadTitle: {type:String, default: ''},

  },
  watch: {
    elements(newElements) {
      this.elementList = [...this.elementsFiltered] = [...newElements];
      this.extractPaginationData();
    },
    pagination(newPagination) {
      if (newPagination.per_page) {
        this.pagination.per_page = newPagination.per_page;
      }

      if (newPagination.total) {
        this.pagination.total = newPagination.total;
      }

      this.extractPaginationData();
    },
    currentPage() {
      this.handleChangePage()
    }
  },
  computed: {
    headList() {
      return this.config.map((element) => ({ 'field': element.field, 'title': element.head, 'sortable': element.sortable }))
    },
    fieldList() {
      return this.config.map((element) => ({ 'type': element.type ? element.type : '', 'value': element.field, 'editable': element.editable, 'options': element.options }))
    },
    searchList() {
      return this.config.map((element) => ({ 'field': element.field, 'value': element.searchable, 'options': element.searchableoptions, 'default': element.default, placeholder: element.placeholder }))
    },
    hasSearchList() {
        return this.searchList.find(element => { return element.value === true}) !== undefined;
      },
    hasEditable() {
      return this.config.find((element) => element.editable) !== undefined
    },
    hasPagination() {
      if (this.pagination && this.draggable) {
        console.warn(`Pagination and draggable options enabled can cause unexpected behavior. "pagination" has been disabled`)
        return false;
      }

      return this.pagination !== null;
    },
    totalPages() {
      return this.sst ? Math.ceil(this.pagination.total / this.pagination.per_page) : Math.ceil(this.elementsFiltered.length / this.perPage)
    },
    elementsInCurrentPage: {
      get() {
        if (this.sst) {
          return this.elementsFiltered;
        } else {
          if (this.hasPagination) {
            return this.elementsFiltered.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
          } else {
            return this.elementsFiltered;
          }
        }
      },
      set(elementsInNewOrder) {
        this.elementsFiltered = elementsInNewOrder;
        this.$emit('sort',elementsInNewOrder.map(element => element.uuid));
      }
    }
  },

  created() {
    this.elementList = [...this.elementsFiltered] = [...this.elements];

    this.extractPaginationData();
  },

  methods: {
    disableEditing(index) {
      let elementToRemove = this.elements[index]
      this.editedRows = this.editedRows.filter((row) => row !== elementToRemove)
    },

    disableAllEditing() {
      this.editedRows = [];
    },

    extractPaginationData() {
      if (this.hasPagination && this.pagination.per_page) {
        this.perPage = this.pagination.per_page;
      }
    },

    handleRefresh() {
      this.$emit('refresh');
    },

    handleSelectFilter(option, field) {
      if (option) {
        this.selectedFilters[field] = option
      } else {
        delete this.selectedFilters[field]
      }
      this.handleFilter()
    },

    handleFilter() {
      const selectedFilters = Object.entries(this.selectedFilters)

      this.currentPage = 1
      if (this.sst) {
        this.filters = []
        let fields = this.$refs.datatableSearch.$refs.searchcell.map((searchcell) =>  {
          const keys = Object.keys(searchcell.$refs)
          return keys[0]
        })
        for ( const [index, field] of fields.entries()) {
          const fieldObject = this.$refs.datatableSearch.$refs.searchcell[index].$refs[field]
          if (fieldObject) {
            let value = fieldObject.value

            if (value && value.length) {
              this.filters.push({ 'field': field, 'value': value })
            }
          }
        }
          selectedFilters.forEach(element => {
            this.filters.push({ 'field': element[0], 'value': element[1].value })
          }
        );

        this.emitQuery();
      } else {
        this.resetFilters()

        let fields = this.$refs.datatableSearch.$refs.searchcell.map((searchcell) =>  {
          const keys = Object.keys(searchcell.$refs)
          return keys[0]
        })

        for (const [index, field] of fields.entries()) {
          const fieldObject = this.$refs.datatableSearch.$refs.searchcell[index].$refs[field]

          if (fieldObject) {
            let value = fieldObject.value
            if (value) {
              this.elementsFiltered = this.elementsFiltered.filter((element) => {
                return element[field] ? element[field].toString().toLowerCase().includes(value.toLowerCase()) : false;
              });
            }
          }
        }

        for (let index in selectedFilters) {
          let field = selectedFilters[index][0];
          let value = selectedFilters[index][1];
          this.elementsFiltered = this.elementsFiltered.filter((element) => {
            if(element[field].label) {
                return element[field] ? element[field].label.includes(value.label) : false;
            } else {
              return element[field] == value.value
            }

          })
        }
      }

      this.extractPaginationData();
    },

    handleSort(sortedBy) {
      this.resetFilters();

      if (this.sst) {
        this.sort = {};
        if(typeof sortedBy.value !== 'boolean') {
          this.sort = sortedBy;
        }

        this.emitQuery();
      } else {
        if (sortedBy.value === 'asc') {
          this.elementsFiltered.sort((a,b) => (a[sortedBy.field] < b[sortedBy.field]) ? 1 : ((b[sortedBy.field] < a[sortedBy.field]) ? -1 : 0))
        } else if (sortedBy.value === 'desc') {
          this.elementsFiltered.sort((a,b) => (a[sortedBy.field] > b[sortedBy.field]) ? 1 : ((b[sortedBy.field] > a[sortedBy.field]) ? -1 : 0))
        } else {
          this.elementsFiltered = [...this.elementList];
        }
      }
    },

    resetFilters() {
      this.elementsFiltered = [...this.elementList];
    },

    handleCheckbox(uuid, value, field) {
      this.$emit('checkbox', uuid, !value, field.value);
    },
    handleChangePage() {
      if (this.sst) {
        this.emitQuery();
      }
    },
    emitQuery() {
      let params = { 'page' : this.currentPage };

      if (Object.keys(this.sort).length > 0) {
        params.sort_type = this.sort.value
        params.sort_by = this.sort.field
      }

      params.filters = this.filters
      params.filters = params.filters.concat(this.globalFilters)

      this.$emit('filter', params)
    },
    handleAction(type, uuid, element) {
      if (type === 'delete') {
        this.$vs.dialog({
          type:'confirm',
          color:'danger',
          title: this.deleteDialog ? this.deleteDialog.title : this.$t("shops.delete"),
          text: this.deleteDialog ? this.deleteDialog.text : this.$t("question.sure"),
          accept: () => this.$emit('action', type, uuid, element)
        })
      } else {
        this.$emit('action', type, uuid, element);
      }
    },
    handleEditRow(element) {
      if(!this.editedRows.includes(element)) {
        this.editedRows.push(element);
        element.edited=1
      }
    },
    
    saveAllRows() {
      this.$emit('action', 'saveAllRows');
    },
  }
}
</script>

<style>
.vs-table--content, .vs-table--tbody {
  overflow-x: scroll !important;
  overflow-y: visible !important;
}

.vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
    z-index: inherit;
    -webkit-transform: none;
    transform: none;
}

.vs-table--tbody-table th:first-child, .vs-table--tbody-table td:first-child {
  padding-left: 2rem !important;
}


.vs-con-table .vs-con-tbody {
  border: solid 1px #ebe9f1
}

.pagination {
  @apply mt-2
}

.vs-table--tbody-table .tr-values td {
    padding: 5px;
}

.sk-datatable .cell {
  min-width: 100px;
}

</style>
