var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticClass: "w-1/2",
    attrs: { multiple: "", options: this.field.options },
    on: {
      input: function($event) {
        return _vm.$emit("edit", _vm.element)
      }
    },
    model: {
      value: _vm.inputVal,
      callback: function($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }