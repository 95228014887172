<template>
  <div class="inline action-button">
    <vs-button v-if="this.type === 'view'" type="border" class="float-right mr-2 px-3 rounded-lg" @click="handleAction">
      <feather-icon icon="EyeIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
    </vs-button>
    <vx-tooltip  v-else-if="this.type === 'clone'" :text="$t('copy-button-tooltip')" position="top" delay=".3s" class="m-0">
      <vs-button type="border" class="mr-2 px-5 rounded-lg" @click="handleAction">
        <div class="flex flex-nowrap">
          <feather-icon icon="CopyIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
        </div>
      </vs-button>
    </vx-tooltip>
    <vs-button v-else-if="this.type === 'edit'" type="border" class="mr-2 px-5 rounded-lg" @click="handleAction">
      <div class="flex flex-nowrap">
        <feather-icon icon="Edit2Icon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
        <span class="hidden 2xl:inline-block ml-2">
          {{ $t("datatable.button.edit") }}
        </span>
      </div>
    </vs-button>
    <vs-button v-else-if="this.type === 'download'" type="border" class="mr-2 rounded-lg" @click="handleAction">
      <div class="flex flex-nowrap">
        <feather-icon icon="DownloadIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
        <span class="hidden 2xl:inline-block ml-2">
          {{ $t("datatable.button.download") }}
        </span>
      </div>
    </vs-button>
    <vs-button v-else-if="this.type === 'delete'" type="border" color="danger" class="delete-row rounded-lg px-3 py-2 mr-2 text-danger" @click="handleAction">
      <feather-icon icon="TrashIcon" svgClasses="align-middle w-6"/>
    </vs-button>
    <vs-button v-else-if="this.type === 'editRow' && isEdited" type="border" class="editRow rounded-lg px-3 py-2 mr-2" @click="handleAction">
      <feather-icon icon="SaveIcon" svgClasses="align-middle w-6" class="align-middle"/>
    </vs-button>
   
    <div v-else-if = "!isInArray">
      <SKDataTableCustomAction>
        <component :is="customSlot" :properties="customSlotProperties" ></component>
      </SKDataTableCustomAction>
    </div>
  </div>
</template>

<script>
import SKDataTableCustomAction from './SKDataTableCustomAction.vue';
export default {
  name:'data-table-action',
  props:{
    type: String,
    uuid: String,
    element: Object,
    isEdited: {type: Boolean, default: false},
    customActions: {type: Array, required:false}
  },
  components: {
    SKDataTableCustomAction
  },
  computed: {
    isInArray() {
      return this.specificTypes.includes(this.type);
    }
  },
  data(){
    return{
      specificTypes : ['view','download','clone','edit','delete'],
      customSlot:{},
      customSlotProperties: {
        type: this.type,
        uuid: this.uuid,
        rowInfo: this.element
      }
    }
  },
  beforeMount(){
    if(this.customActions) {
      this.customActions.forEach((item) => {  
        if (item.actionName == this.type){
          this.customSlot = item.component
          this.customSlotProperties = {...this.customSlotProperties,...item.props}
        }
      })
         
    }
  },
  
  methods: {
    handleAction() {
      this.$emit('action', this.type, this.uuid, this.element)
    },

  }
}
</script>

<style scoped>
.action-button button {
    @apply h-12 !important;
}
</style>
