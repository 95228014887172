<template>
  <div @click="() => $emit( 'check', element.uuid, element[field.value], field)">
    <vs-checkbox v-model="element[field.value]"/>
  </div>
</template>
<script>
export default {
  name: 'data-table-cell-checkbox',
  props: {
    element: Object,
    field: Object,
  }
}
</script>