<template>
  <div>
    <v-select v-if="field.options !== undefined"
      :ref="`cell-${field.value}-${element.name}`"
      class="bg-white"
      v-model="inputVal"
      :options="field.options"
      @input="$emit('edit', element)"
    >                       
    <template  #option="option">
      <span v-if="option.icon" class="w-8 inline-block text-center"><img class="w-6" :src="option.icon"></span>{{ option.label }}
    </template>
    <template  #selected-option="option">
      <span v-if="option.icon"  class="w-8"><img class="w-6" :src="option.icon"></span>{{ option.label }}
    </template>
    </v-select>
    <input  v-else
      class="vs-inputx vs-input--input normal h-10"
      v-model="inputVal"
      @keyup.enter="$emit('edit', element)"
      @blur="$emit('edit', element)"
    />
  </div>
</template>
<script>
export default {
  name: 'data-table-cell-editable',
  props: {
    element: Object,
    field: Object,
  },
  computed: {
    inputVal: {
      get(){
        return this.element[this.field.value];
      },
      set(val) {
        this.$emit('input', val);
      }  
    }  
  }
}
</script>