<template>
  <div class="image">
    <img :src="element[field.value]" v-bind:class="{ bigImage: field.type === 'bigimage', smallImage: field.type === 'smallimage' }">
  </div>
</template>

<script>
export default {
  name: 'data-table-cell-image',
  props: {
    element: Object,
    field: Object,
  }
}
</script>

<style scoped>
.image img {
  object-fit: cover;
  height: 58px;
}

.smallImage {
  height: 36px !important;
}

.bigImage {
  width: 268px !important;
  height: auto !important;
}
</style>