var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("thead", { staticClass: "vs-table-thead" }, [
    _c(
      "tr",
      { staticClass: "table-header" },
      [
        _vm.draggable
          ? _c("th", { staticClass: "w-20 ml-1" }, [
              _vm._v(_vm._s(_vm.draggableHeadTitle))
            ])
          : _vm._e(),
        _vm._l(_vm.headList, function(head, index) {
          return _c("th", { key: index }, [
            _c(
              "div",
              {
                staticClass: "vs-table-text font-bold uppercase",
                on: {
                  click: function($event) {
                    return _vm.sort(head)
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(head.title) + " "),
                head.sortable &&
                _vm.sortedBy.value &&
                _vm.sortedBy.field === head.field
                  ? _c("span", { staticClass: "sort-th" }, [
                      _c(
                        "i",
                        {
                          staticClass:
                            "vs-icon notranslate icon-scale th-sort-icon material-icons null"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.sortedBy.value === "asc"
                                ? "expand_less"
                                : "expand_more"
                            )
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }