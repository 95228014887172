<template>
	<div>
		<v-select
			v-if="config.options"
			class="bg-white search-select rounded-lg"
			:ref="config.field"
			@input="handleSelectChange"
			:options="config.options"
			:value="selectValue"
			:placeholder="placeholder"
			:appendToBody="true"
		>
			<template #option="option">
				<span v-if="option.icon" class="w-8 inline-block text-center">
					<img class="w-6" :src="option.icon" />
				</span>
				{{ option.label }}
			</template>
			<template #selected-option="option">
				<span v-if="option.icon" class="w-8">
					<img class="w-6" :src="option.icon" />
				</span>
				{{ option.label }}
			</template>
		</v-select>

		<input
			v-else-if="config.value"
			:data-field="config.field"
			:ref="config.field"
			class="input-filter vs-inputx vs-input--input normal h-10 rounded-lg"
			@keyup.enter="$emit('filter')"
			@blur="$emit('filter')"
			:value="selectValue"
		/>
	</div>
</template>
<script>
export default {
	name: "data-table-search-cell",
	props: {
		config: Object,
	},
	data: () => {
		return {
			selectValue: undefined,
			placeholder: undefined,
		};
	},
	computed: {},
	created() {
		if (this.config.options) {
			if (this.config.default !== undefined) {
				this.selectValue = {
					label: this.config.default,
					value: this.config.default,
				};
			}
		}
		
		if (!this.config.options && this.config.value) {
			this.selectValue = this.config.default;
		}

		if (this.config.placeholder) {
			this.placeholder = this.config.placeholder
		}
	},
	methods: {
		handleSelectChange(option) {
			this.selectValue = option;
			this.$emit("search", option, this.config.field);
		},
	},
};
</script>
<style scoped>
	.search-select {
		min-width: 100px;
	}
</style>