<template>
  <tr class="tr-values vs-table--tr tr-table-state-null">

    <td v-if="draggable">
        <feather-icon icon="MenuIcon" svgClasses="cursor-pointer text-gray align-middle w-6 ml-3 "/>
    </td>

    <td class="cell" :key="indextd" v-for="(field, indextd) in fieldList">
      <div class="flex items-center min-h-16">
        <div v-if="field.type == 'actions'" class="flex w-full">
          <DataTableAction class="flex" v-for="(action, index) in element[field.value]" :key="index"
          :type="action"
          :uuid="element.uuid"
          :element="element"
          :isEdited="edited"
          :customActions = "customActions"
          @action="$emit('action', action, element.uuid, element)"
          />
        </div>

        <DataTableEditable v-else-if="field.editable"
        class="w-full"
          :ref="`${field.value}-${element.name}`"
          v-model="element[field.value]"
          :field="field"
          :element="element"
          @edit="handleEdit"
        />

        <DataTableImage v-else-if="field.type == 'image' || field.type == 'bigimage' || field.type == 'smallimage'" :field="field" :element="element"/>

        <DataTableLink v-else-if="field.type == 'link'" :field="field" :element="element"/>

        <DataTableCheckbox v-else-if="field.type == 'check'" :field="field" :element="element" @check="handleCheck"/>

        <DataTableMultiselect v-else-if="field.type === 'multiselect'"
                              :field="field"
                              :element="element"
                              v-model="element[field.value]"
                              @edit="handleEdit"
        />
        <div v-else-if="field.type == 'html'" v-html="element[field.value]"/>
        <div v-else>
          {{ element[field.value] }}
        </div>
      </div>
    </td>
  </tr>

</template>
<script>
import DataTableAction from '@/modules/Shared/Components/table/SKDataTableAction.vue'
import DataTableImage from '@/modules/Shared/Components/table/cell/SKDataTableImage.vue'
import DataTableLink from '@/modules/Shared/Components/table/cell/SKDataTablelink.vue'
import DataTableCheckbox from '@/modules/Shared/Components/table/cell/SKDataTableCheckbox.vue'
import DataTableMultiselect from '@/modules/Shared/Components/table/cell/SKDataTableMultiselect.vue'
import DataTableEditable from '@/modules/Shared/Components/table/cell/SKDataTableEditable.vue'

export default {
  name: 'data-table-row',
  components: {
    DataTableAction,
    DataTableImage,
    DataTableLink,
    DataTableCheckbox,
    DataTableMultiselect,
    DataTableEditable
  },
  props: {
    draggable: {type:Boolean, default: false},
    edited: {type:Boolean, default: false},
    element: Object,
    fieldList: Array,
    customActions:{type:Array, required:false}
  },
  methods: {
    handleCheck(elementUuid, value, field) {
      this.$emit( 'check', elementUuid, value, field)
    },

    handleEdit(element) {
      this.$emit('edit', element)
    }
  }
  
}
</script>
<style scoped>
.vs-table--tbody-table .tr-values td {
  padding: 5px;
}
</style>
