var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function() {
          return _vm.$emit(
            "check",
            _vm.element.uuid,
            _vm.element[_vm.field.value],
            _vm.field
          )
        }
      }
    },
    [
      _c("vs-checkbox", {
        model: {
          value: _vm.element[_vm.field.value],
          callback: function($$v) {
            _vm.$set(_vm.element, _vm.field.value, $$v)
          },
          expression: "element[field.value]"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }