var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.config.options
        ? _c("v-select", {
            ref: _vm.config.field,
            staticClass: "bg-white search-select rounded-lg",
            attrs: {
              options: _vm.config.options,
              value: _vm.selectValue,
              placeholder: _vm.placeholder,
              appendToBody: true
            },
            on: { input: _vm.handleSelectChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function(option) {
                    return [
                      option.icon
                        ? _c(
                            "span",
                            { staticClass: "w-8 inline-block text-center" },
                            [
                              _c("img", {
                                staticClass: "w-6",
                                attrs: { src: option.icon }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(option.label) + " ")
                    ]
                  }
                },
                {
                  key: "selected-option",
                  fn: function(option) {
                    return [
                      option.icon
                        ? _c("span", { staticClass: "w-8" }, [
                            _c("img", {
                              staticClass: "w-6",
                              attrs: { src: option.icon }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(option.label) + " ")
                    ]
                  }
                }
              ],
              null,
              false,
              2712792283
            )
          })
        : _vm.config.value
        ? _c("input", {
            ref: _vm.config.field,
            staticClass:
              "input-filter vs-inputx vs-input--input normal h-10 rounded-lg",
            attrs: { "data-field": _vm.config.field },
            domProps: { value: _vm.selectValue },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("filter")
              },
              blur: function($event) {
                return _vm.$emit("filter")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }