var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.field.options !== undefined
        ? _c("v-select", {
            ref: "cell-" + _vm.field.value + "-" + _vm.element.name,
            staticClass: "bg-white",
            attrs: { options: _vm.field.options },
            on: {
              input: function($event) {
                return _vm.$emit("edit", _vm.element)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function(option) {
                    return [
                      option.icon
                        ? _c(
                            "span",
                            { staticClass: "w-8 inline-block text-center" },
                            [
                              _c("img", {
                                staticClass: "w-6",
                                attrs: { src: option.icon }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm._v(_vm._s(option.label) + " ")
                    ]
                  }
                },
                {
                  key: "selected-option",
                  fn: function(option) {
                    return [
                      option.icon
                        ? _c("span", { staticClass: "w-8" }, [
                            _c("img", {
                              staticClass: "w-6",
                              attrs: { src: option.icon }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(_vm._s(option.label) + " ")
                    ]
                  }
                }
              ],
              null,
              false,
              1501400443
            ),
            model: {
              value: _vm.inputVal,
              callback: function($$v) {
                _vm.inputVal = $$v
              },
              expression: "inputVal"
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputVal,
                expression: "inputVal"
              }
            ],
            staticClass: "vs-inputx vs-input--input normal h-10",
            domProps: { value: _vm.inputVal },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("edit", _vm.element)
              },
              blur: function($event) {
                return _vm.$emit("edit", _vm.element)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputVal = $event.target.value
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }