<template>
  <v-select multiple
            class="w-1/2"
            v-model="inputVal"
            :options="this.field.options"
            @input="$emit('edit', element)"
  />
</template>

<script>
export default {
  name: 'data-table-cell-multiselect',
  props: {
    element: Object,
    field: Object,
  },
  computed: {
    inputVal: {
      get() {
        return this.element[this.field.value];
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>
