var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "vs-component vs-con-table stripe vs-table-primary sk-datatable",
      class: { stripe: _vm.stripe }
    },
    [
      _vm.title
        ? _c("header", { staticClass: "header-table vs-table--header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.title))])
          ])
        : _vm._e(),
      (_vm.paginationPosition === "top" &&
        _vm.hasPagination &&
        _vm.totalPages > 1) ||
      _vm.showRefreshButton
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-row items-center gap-2 align-middle mb-2 justify-end"
            },
            [
              _vm.paginationPosition === "top" &&
              _vm.hasPagination &&
              _vm.totalPages > 1
                ? _c("vs-pagination", {
                    staticClass: "pagination",
                    attrs: { total: _vm.totalPages },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                : _vm._e(),
              _vm.showRefreshButton
                ? _c("vs-icon", {
                    staticClass: "text-3xl mr-2 cursor-pointer",
                    attrs: { icon: "refresh", round: "", color: "primary" },
                    on: { click: _vm.handleRefresh }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "con-tablex vs-table--content" },
        [
          _c("div", { staticClass: "vs-con-tbody vs-table--tbody" }, [
            _c(
              "table",
              { staticClass: "vs-table vs-table--tbody-table" },
              [
                _c("DataTableHeader", {
                  ref: "datatableSort",
                  attrs: {
                    draggable: _vm.draggable,
                    draggableHeadTitle: _vm.draggableHeadTitle,
                    headList: _vm.headList
                  },
                  on: { sort: _vm.handleSort }
                }),
                _c("DataTableSearch", {
                  ref: "datatableSearch",
                  attrs: {
                    draggable: _vm.draggable,
                    searchList: _vm.searchList
                  },
                  on: {
                    search: _vm.handleSelectFilter,
                    filter: _vm.handleFilter
                  }
                }),
                _c(
                  "draggable",
                  {
                    attrs: { tag: "tbody", disabled: !_vm.draggable },
                    model: {
                      value: _vm.elementsInCurrentPage,
                      callback: function($$v) {
                        _vm.elementsInCurrentPage = $$v
                      },
                      expression: "elementsInCurrentPage"
                    }
                  },
                  _vm._l(_vm.elementsInCurrentPage, function(element, index) {
                    return _c("DataTableRow", {
                      key: element.uuid || element.id || index,
                      attrs: {
                        draggable: _vm.draggable,
                        edited: _vm.editedRows.includes(element),
                        fieldList: _vm.fieldList,
                        element: element,
                        customActions: _vm.customActions
                      },
                      on: {
                        edit: _vm.handleEditRow,
                        check: _vm.handleCheckbox,
                        action: _vm.handleAction
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]),
          _vm.paginationPosition === "bottom" &&
          _vm.hasPagination &&
          _vm.totalPages > 1
            ? _c("vs-pagination", {
                staticClass: "pagination",
                attrs: { total: _vm.totalPages },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.hasEditable
        ? _c(
            "div",
            { staticClass: "float-right mt-10 disabled" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "button-save-all",
                  attrs: {
                    disabled: _vm.editedRows.length === 0,
                    color: "primary",
                    type: "filled rounded-lg w-48 mr-5"
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveAllRows()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled rounded-lg w-48" },
                  on: {
                    click: function($event) {
                      return _vm.addRow()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }