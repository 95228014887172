<template>
  <a :href="element[field.value]" class="overflow-ellipsis">
    {{ element[field.value] | ellipsis(70) }}
  </a>
</template>
<script>
export default {
  name: 'data-table-cell-link',
  props: {
    element: Object,
    field: Object,
  },
}
</script>